/*_----------dinh dang chung--------------*/
.pd1 {
  padding: 1rem 0;
}
/*-----------cac lop--------------------------------------------*/
.title-page{
    font-size:20px;
}
.an_the{
    display:none;
}
/*-------pagination----------------------*/

.pagination a {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #975aff;
  background-color: #f5f5f5;
  border: 1px solid #e0e0e0;
}
/*------ban hang-------------------------------------------------*/
.nav-tabs > li > a {
  font-weight: bold;
  color: #515253;
  background-color: #fff;
  margin: 0;
  border-radius: 0;
  padding: 10px 20px;
}
.nav-tabs-bordered > li.active > a {
  border-color: #9474a9 !important;
  border-width: 0 0 2px !important;
  color: #303030 !important;
  border: 1px solid #dde6e9;
}
.headerAuto .add {
  background: #14a1d4 none repeat scroll 0 0;
  border: medium none;
  border-radius: 2px;
  bottom: 2px;
  height: 32px;
  margin: 0;
  position: absolute;
  right: 1px;
  text-shadow: none;
  top: 2px;
  width: 32px;
}
.kvheaderTitle i {
  margin: 10px;
  color: #fff;
}
.mb10 {
  margin-bottom: 10px !important;
}
.vd-quick-key-page, .vd-quick-key-page-column {
  align-content: flex-start;
  align-items: flex-start;
  display: flex;
  flex-wrap: wrap;
}

.vd-quick-key-page-column {
  /*flex: 1 1 0;*/
  flex-direction: column;
  padding: 0 2px;
}

.vd-quick-key {
  border: 1px solid #b5b5b5;
  border-radius: 3px;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  height: 110px;
  margin: 2px 0;
  min-width: 103px;
  position: relative;
  width: 130px;
}

.vd-quick-key--white {
  background-color: #62676f;
  border-color: #565a61;
}

.vd-quick-key-inner {
  align-items: center;
  display: flex;
  flex: 1 1 0;
  flex-direction: column;
  justify-content: space-around;
}

.vd-quick-key--quick-key .vd-quick-key-inner {
  -moz-border-bottom-colors: none;
  -moz-border-left-colors: none;
  -moz-border-right-colors: none;
  -moz-border-top-colors: none;
  background: #fff none repeat scroll 0 0;
  border-color: -moz-use-text-color #b5b5b5 #b5b5b5;
  border-image: none;
  border-radius: 0 0 3px 3px;
  border-style: none solid solid;
  border-width: medium 1px 1px;
  margin: 8px -1px -1px;
}

.vd-quick-key-label {
  font-size: 12px;
  line-height: 1.3;
  overflow: hidden;
  text-align: center;
  word-wrap: break-word;
}

.vd-quick-key--blank {
  border-color: #ebebeb;
  cursor: default;
}

.wr-workspace-module-container {
  flex: 1 1 0;
  margin-top: 20px;
  position: relative;
  width: 100%;
}

.wr-workspace-module {
  display: flex;
  justify-content: center;
}

.wr-workspace-quickkey-setup-row {
  align-items: center;
  display: flex;
  flex-direction: column;
  text-align: center;
}

.wr-workspace-quickkey-setup {
  justify-content: center;
}

.vd-header {
  color: #414141;
  display: block;
  font-family: lato,"Helvetica Neue",Helvetica,Roboto,Arial,sans-serif;
  font-weight: 700;
  margin: 0;
}

.vd-header--page {
  font-size: 35px;
  font-weight: 700;
  position: relative;
}

.wr-workspace-quickkey-setup--default .wr-workspace-quickkey-setup-splash-default {
  background: rgba(0, 0, 0, 0) url("/Administration/Content/images/qickkeys.png") no-repeat scroll center center;
  flex-basis: 355px;
  margin-top: -80px;
  width: 461px;
}

.wr-workspace-quickkey-splash-default-content {
  padding: 0 90px;
}

.wr-workspace-quickkey-setup--default .wr-workspace-quickkey-splash-default-content {
  margin-top: -80px;
}

.vd-text--secondary {
  color: #959595;
}

.vd-mtl {
  margin-top: 20px;
}

.vd-button.vd-button--secondary {
  background-color: #446a81;
  color: #fff;
}

  .vd-button.vd-button--secondary:not(.vd-button--icon) {
    min-width: 120px;
  }

.vd-edit-qk-modal-content {
  display: flex;
}

.vd-quick-key--read-only {
  pointer-events: none;
}

.vd-quick-key--red {
  background-color: #d77173;
  border-color: #d15d5f;
}

.vd-edit-qk-modal-content .vd-quick-key {
  width: 100px;
}

.vd-mbxl {
  margin-bottom: 40px;
}

.vd-mll {
  margin-left: 20px;
}

.vd-edit-qk-modal-fields {
  flex: 1 1 0;
}

.vd-field {
  flex: 1 1 0;
  font-size: 15px;
  margin-bottom: 20px;
}

  .vd-field .vd-label {
    display: inline-block;
    margin-bottom: 5px;
  }

  .vd-field .vd-value {
    position: relative;
  }

.vd-qk-color-palette {
  list-style: outside none none;
  margin: 0;
  padding: 8px 0 0;
}

.vd-quick-keys {
  -moz-user-select: none;
  display: flex;
  flex-direction: column;
  font-family: lato,"Helvetica Neue",Helvetica,Roboto,Arial,sans-serif;
  position: relative;
}

.vd-quick-keys {
  -moz-user-select: none;
}

.vd-quick-key-grid {
  display: flex;
  flex: 1 1 0;
  flex-direction: column;
  overflow-y: auto;
}

  .vd-quick-key-grid:focus {
    outline: 0 none;
  }

.vd-quick-key-grid--masked {
  visibility: hidden;
}

.vd-quick-key-page {
  flex-shrink: 0;
}

.vd-quick-key-folder-view-container {
  bottom: 0;
  display: flex;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transform: scale(1);
  transition: transform 0.15s ease-out 0s, opacity 0.15s ease-out 0s, -webkit-transform 0.15s ease-out 0s;
}

  .vd-quick-key-folder-view-container.ng-enter, .vd-quick-key-folder-view-container.ng-leave.ng-leave-active {
    opacity: 0;
    transform: scale(0.8);
  }

  .vd-quick-key-folder-view-container.ng-enter-active, .vd-quick-key-folder-view-container.ng-leave {
    opacity: 1;
    transform: scale(1);
  }

.vd-quick-key-folder-view {
  border: 1px solid #b5b5b5;
  border-radius: 3px;
  display: flex;
  flex: 1 1 0;
  flex-direction: column;
}

.vd-quick-key-folder-header {
  border-bottom: 1px solid #b5b5b5;
  color: #fff;
  font-size: 16px;
  font-weight: 300;
  line-height: 32px;
  padding: 0 45px;
  position: relative;
  text-align: center;
}

.vd-quick-key-folder-action {
  background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
  border: medium none;
  line-height: 28px;
  position: absolute;
  width: 45px;
}

.vd-quick-key-folder-action--edit {
  left: 0;
}

.vd-quick-key-folder-action--close {
  right: 0;
}

.vd-quick-key-folder-view--red {
  background-color: #f3d4d5;
  border-color: #d15d5f;
}

  .vd-quick-key-folder-view--red .vd-quick-key-folder-header {
    background: #d77173 none repeat scroll 0 0;
    border-color: #d15d5f;
  }

.vd-quick-key-folder-view--orange {
  background-color: #fff;
  border-color: #f28775;
}

  .vd-quick-key-folder-view--orange .vd-quick-key-folder-header {
    background: #f49b8c none repeat scroll 0 0;
    border-color: #f28775;
  }

.vd-quick-key-folder-view--yellow {
  background-color: #fdf8ef;
  border-color: #f0b664;
}

  .vd-quick-key-folder-view--yellow .vd-quick-key-folder-header {
    background: #f2c17b none repeat scroll 0 0;
    border-color: #f0b664;
  }

.vd-quick-key-folder-view--green {
  background-color: #b3c7cb;
  border-color: #5d8089;
}

  .vd-quick-key-folder-view--green .vd-quick-key-folder-header {
    background: #678e98 none repeat scroll 0 0;
    border-color: #5d8089;
  }

.vd-quick-key-folder-view--blue {
  background-color: #f3f8fa;
  border-color: #87becc;
}

  .vd-quick-key-folder-view--blue .vd-quick-key-folder-header {
    background: #99c8d4 none repeat scroll 0 0;
    border-color: #87becc;
  }

.vd-quick-key-folder-view--purple {
  background-color: #e2d7e6;
  border-color: #9f78ab;
}

  .vd-quick-key-folder-view--purple .vd-quick-key-folder-header {
    background: #aa88b5 none repeat scroll 0 0;
    border-color: #9f78ab;
  }

.vd-quick-key-folder-view--white {
  background-color: #a3a7ae;
  border-color: #565a61;
}

  .vd-quick-key-folder-view--white .vd-quick-key-folder-header {
    background: #62676f none repeat scroll 0 0;
    border-color: #565a61;
  }

.vd-quick-key--red {
  background-color: #d77173;
  border-color: #d15d5f;
}

  .vd-quick-key--red.vd-quick-key--hover, .vd-quick-key--red:focus {
    background-color: #cc494c;
    border-color: #c4373a;
  }

.vd-quick-key--orange {
  background-color: #f49b8c;
  border-color: #f28775;
}

  .vd-quick-key--orange.vd-quick-key--hover, .vd-quick-key--orange:focus {
    background-color: #f0735d;
    border-color: #ed5e46;
  }

.vd-quick-key--yellow {
  background-color: #f2c17b;
  border-color: #f0b664;
}

  .vd-quick-key--yellow.vd-quick-key--hover, .vd-quick-key--yellow:focus {
    background-color: #edab4d;
    border-color: #eba035;
  }

.vd-quick-key--green {
  background-color: #678e98;
  border-color: #5d8089;
}

  .vd-quick-key--green.vd-quick-key--hover, .vd-quick-key--green:focus {
    background-color: #52727a;
    border-color: #48636a;
  }

.vd-quick-key--blue {
  background-color: #99c8d4;
  border-color: #87becc;
}

  .vd-quick-key--blue.vd-quick-key--hover, .vd-quick-key--blue:focus {
    background-color: #75b5c5;
    border-color: #63abbd;
  }

.vd-quick-key--purple {
  background-color: #aa88b5;
  border-color: #9f78ab;
}

  .vd-quick-key--purple.vd-quick-key--hover, .vd-quick-key--purple:focus {
    background-color: #9469a1;
    border-color: #875c94;
  }

.vd-quick-key--white {
  background-color: #62676f;
  border-color: #565a61;
}

  .vd-quick-key--white.vd-quick-key--hover, .vd-quick-key--white:focus {
    background-color: #4a4e54;
    border-color: #3e4146;
  }

.vd-quick-key-inner {
  align-items: center;
  display: flex;
  flex: 1 1 0;
  flex-direction: column;
  justify-content: center;
  padding: 4px;
  position: relative;
}

.vd-quick-key--quick-key .vd-quick-key-inner {
  -moz-border-bottom-colors: none;
  -moz-border-left-colors: none;
  -moz-border-right-colors: none;
  -moz-border-top-colors: none;
  background: #fff none repeat scroll 0 0;
  border-color: -moz-use-text-color #b5b5b5 #b5b5b5;
  border-image: none;
  border-radius: 0 0 3px 3px;
  border-style: none solid solid;
  border-width: medium 1px 1px;
  margin: 8px -1px -1px;
}

.vd-quick-key-grid--dragging .vd-quick-key-inner {
  pointer-events: none;
}

.vd-quick-key--quick-key.vd-quick-key--hover .vd-quick-key-inner, .vd-quick-key--quick-key:focus .vd-quick-key-inner {
  background: #f2f2f2 none repeat scroll 0 0;
}

.vd-quick-key-label {
  font-size: 12px;
  line-height: 1.3;
  overflow: hidden;
  text-align: center;
  word-wrap: break-word;
}

.vd-quick-key-image {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  /*flex: 1 1 0;*/
  height: 100%;
  width: 100%;
}

.vd-quick-key--folder {
  color: #fff;
}

.vd-quick-key-folder-count {
  font-size: 12px;
  position: absolute;
  right: 8px;
  top: 5px;
}

.vd-quick-key--blank {
  border-color: #ebebeb;
  cursor: default;
}

.vd-quick-keys--edit-mode .vd-quick-key--blank {
  border-style: dotted;
  border-width: 2px;
  cursor: pointer;
}

  .vd-quick-keys--edit-mode .vd-quick-key--blank.vd-quick-key--hover {
    border-color: #c5c5c5;
  }

  .vd-quick-keys--edit-mode .vd-quick-key--blank.vd-quick-key--target {
    border-color: #1a95ea;
  }

.vd-quick-key--loader {
  display: flex;
  pointer-events: none;
}

.vd-quick-key--drag-over::after {
  background-color: #a6d6f7;
  border-radius: 3px;
  bottom: -1px;
  content: "";
  left: -1px;
  position: absolute;
  right: -1px;
  top: -1px;
}

.vd-quick-key--not-allowed::after {
  background-color: #e6643c;
  border-radius: 3px;
  bottom: -1px;
  content: "";
  left: -1px;
  position: absolute;
  right: -1px;
  top: -1px;
}

.vd-quick-key--locked {
  background-color: #d7d7d7 !important;
  border: 1px solid #b5b5b5 !important;
  justify-content: center;
}

.vd-quick-key--read-only {
  pointer-events: none;
}

.vd-edit-qk-modal-content {
  display: flex;
}

  .vd-edit-qk-modal-content .vd-quick-key {
    width: 100px;
  }

.vd-edit-qk-modal-fields {
  flex: 1 1 0;
}

.vd-qk-color-palette {
  list-style: outside none none;
  margin: 0;
  padding: 8px 0 0;
}

.vd-qk-color-palette-item {
  display: inline-block;
}

  .vd-qk-color-palette-item:not(:last-child) {
    margin-right: 10px;
  }

.vd-qk-color-palette-color--red {
  background-color: #d77173;
}

  .vd-qk-color-palette-color--red::after {
    border-color: #d77173;
  }

.vd-qk-color-palette-color--orange {
  background-color: #f49b8c;
}

  .vd-qk-color-palette-color--orange::after {
    border-color: #f49b8c;
  }

.vd-qk-color-palette-color--yellow {
  background-color: #f2c17b;
}

  .vd-qk-color-palette-color--yellow::after {
    border-color: #f2c17b;
  }

.vd-qk-color-palette-color--green {
  background-color: #678e98;
}

  .vd-qk-color-palette-color--green::after {
    border-color: #678e98;
  }

.vd-qk-color-palette-color--blue {
  background-color: #99c8d4;
}

  .vd-qk-color-palette-color--blue::after {
    border-color: #99c8d4;
  }

.vd-qk-color-palette-color--purple {
  background-color: #aa88b5;
}

  .vd-qk-color-palette-color--purple::after {
    border-color: #aa88b5;
  }

.vd-qk-color-palette-color--white {
  background-color: #62676f;
}

  .vd-qk-color-palette-color--white::after {
    border-color: #62676f;
  }

.vd-qk-color-palette-color {
  border-radius: 50%;
  cursor: pointer;
  display: block;
  height: 25px;
  margin: 5px;
  position: relative;
  width: 25px;
}

  .vd-qk-color-palette-color::after {
    border-radius: 50%;
    border-style: solid;
    border-width: 2px;
    bottom: -5px;
    content: "";
    left: -5px;
    opacity: 0;
    position: absolute;
    right: -5px;
    top: -5px;
  }

.vd-qk-color-palette-input {
  opacity: 0;
  position: absolute;
}

  .vd-qk-color-palette-input:focus + .vd-qk-color-palette-color::after {
    border-color: #1a95ea;
    border-width: 1px;
    box-shadow: 0 0 1px #1a95ea;
  }

  .vd-qk-color-palette-input:checked + .vd-qk-color-palette-color::after {
    opacity: 1;
  }

.vd-flex--justify-end {
  justify-content: flex-end;
}

.vd-button.vd-button--negative {
  background-color: #e6643c;
  color: #fff;
}

.wr-open-register-panel-container {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
}

.wr-open-register-panel--current-sale {
  height: 100%;
  left: 0;
  padding: 0 8px;
  position: absolute;
  top: 0;
  width: 100%;
}

.wr-open-register-panel {
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  justify-content: flex-start;
  padding: 40px 20px;
  text-align: center;
}

.wr-register-closed-image {
  width: 100px;
}

.wr-open-register-panel--current-sale .wr-open-register-panel {
  background-color: #fff;
  border-radius: 3px;
  flex: 1 1 0;
}

.wr-open-register-panel-title {
  font-weight: 400;
  line-height: 1.2;
  margin-top: 15px;
}

.wr-open-register-panel-description {
  margin-bottom: 30px;
  margin-top: 0;
  text-align: center;
}

.vd-button.vd-button--primary:not(.vd-button--icon) {
  min-width: 120px;
}
.bordered-top {
  border-top: 1px solid #e8e8e8 !important;
}
.panel-body {
  padding: 15px;
}

.sale {
  -webkit-box-shadow: 0px 0px 39px 0px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 0px 39px 0px rgba(0,0,0,0.75);
  box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.75);
  display: flex;
  flex: 1 1 0;
  flex-direction: column;
  justify-content: space-between;
  min-height: 0;
}

.sale-customer {
  border-bottom: 1px solid #ebebeb;
  flex-shrink: 0;
}

.sale-customer-add-new {
  align-items: center;
  display: flex;
  min-height: 48px;
  padding: 10px;
}

.sale-list {
  list-style: outside none none;
  margin: 0;
  padding: 0;
}

.sale-list-container {
  flex-grow: 1;
  min-height: 250px;
  overflow-y: auto;
  background-color: #fff;
}

.sale-total-line-item--label {
  float: left;
}

.sale-discount-container .sale-total-line-item--value {
  float: right;
}

.sale-note {
  border-top: 1px solid #ebebeb;
  flex-shrink: 0;
  padding: 10px 20px;
}

.sale-note-add {
  font-size: 14px;
}

.sale-totals-container {
  flex-shrink: 0;
}

.sale-totals {
  top: 100px;
  width: 100%;
}

.bo-top {
  border-top: 1px solid #ebebeb;
}

.sale-discount-container, .sale-total-line {
  background: #fff none repeat scroll 0 0;
  clear: both;
  flex-flow: row wrap;
  font-size: 14px;
  padding: 5px 20px;
  text-align: right;
}

.sale-total-line-item--value {
  flex: initial;
  min-width: 100px;
}

.sale-totals li:last-child {
  padding-top: 10px;
}

.sale-total-line-item--label {
  margin-left: 10px;
}

.product-sale {
}

.typeNumber {
  text-align: right;
}

.s-header .s-header-title > th {
  background: #dcf4fc none repeat scroll 0 0;
  border-color: #eeeeee #c5e7f2 #c5e7f2 !important;
  border-right-style: solid;
  border-right-width: 1px;
  border-top: 1px solid #eeeeee !important;
  color: #292929;
  font-size: 14px;
}

.sale-footer, .sale-header, .sale-list, .sale-list-footer, .sale-list-loader {
  flex-shrink: 0;
}

.li-cell::before {
  background-color: #41af4b;
  content: "";
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  transition: opacity 0.2s ease 0s;
  width: 4px;
}

.li-cell-summary-container {
  align-items: baseline;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  padding: 16px 16px 0 !important;
}

.vd-text--secondary {
  color: #6692b0;
}

.wr-expandable-chevron {
  height: 10px;
  width: 10px;
}

.li-cell-summary {
  display: inline;
}

.li-cell-summary--quantity {
  min-width: 45px;
  padding: 0 16px 0 8px;
  text-align: right;
}

.li-cell-summary--name-container {
  display: flex;
  flex: 1 1 0;
  flex-direction: column;
  min-width: 0;
  padding-bottom: 0px;
}

.line-item--separator .li-cell {
  border-bottom: 1px solid #e5eaed;
}

.vd-flex {
  display: flex;
  flex: 1 1 0;
}

.li-cell-actions {
  vertical-align: middle;
  width: 100%;
}

.li-cell-actions-field {
  display: flex;
  flex: 1 1 0;
  flex-direction: column;
  height: 100%;
}

.vd-field .vd-label {
  display: inline-block;
  font-feature-settings: "liga", "kern";
  font-size: 15px;
  font-synthesis: none;
  font-weight: 700;
  line-height: 1.33333;
  margin: 0 0 5px;
  text-rendering: optimizelegibility;
}

.vd-field .vd-value {
  position: relative;
}

.li-cell-actions-value {
  display: flex;
  flex: 1 1 0;
  flex-direction: column;
  justify-content: center;
}

.li-cell-actions-container {
  padding: 0 16px 10px !important;
  visibility: hidden;
  height: 0px;
}

.li-cell-actions-container--expanded {
  height: 75px;
  opacity: 1;
  visibility: visible;
}

.vd-textarea {
  display: block;
  line-height: 20px;
  overflow: hidden;
  resize: none;
}

.vd-input {
  background-color: #fff;
  border: 2px solid #e5eaed;
  border-radius: 3px;
  box-shadow: none;
  box-sizing: border-box;
  color: #3a4953;
  cursor: text;
  font-family: lato,"Helvetica Neue",Helvetica,Roboto,Arial,sans-serif;
  font-size: 15px;
  font-weight: 400;
  margin: 0;
  outline: 0 none;
  padding: 12px;
  transition-delay: 0s;
  transition-duration: 0.2s;
  transition-property: border-color, box-shadow;
  transition-timing-function: ease;
  width: 100%;
  word-break: normal;
}

.text-center {
  text-align: center;
}

.li-cell-actions:not(:first-child) {
  margin-left: 16px;
}

.li-cell-summary--remove {
  margin-left: 5px;
}

.sale .visible-han {
  display: block !important;
}

.sale .visible-han-an {
  display: none !important;
}
.paypreview {
  -webkit-box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.75);
  -moz-box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.75);
  box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.75);
}
/*==============================================================*/
@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
@media (min-width: 992px){
    .typo-line{
        padding-left: 140px;
        margin-bottom: 40px;
        position: relative;
    }

    .typo-line .category{
        transform: translateY(-50%);
        top: 50%;
        left: 0px;
        position: absolute;
    }
}




@media (max-width: 991px) {
    .sidebar .nav > li.active-pro{
        position: relative;
        width: 100%;
        bottom: 10px;
    }
    .sidebar .nav > li.active-pro > a{
        margin-top: 20px;

    }
}

